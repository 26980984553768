<!--
 * @Description: 搜索模板配置
 * @Autor: WangYuan
 * @Date: 2021-06-10 19:31:01
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-09 20:29:49
-->
<template>
  <div>
    <!-- 风格主题 -->
    <div class="global-template global-template--simplified" style="padding-right:360px">
      <h2 class="global-template-title">全店风格</h2>
      <div style="display: flex;">
        <div class="global-template-left">
          <div class="flex row-between">
            <div class="custom-theme-tips">主题配色</div>
            <el-radio-group v-model="form.colourType" size="mini" class="mr15">
              <el-radio-button label="系统配色"></el-radio-button>
              <el-radio-button label="自定义配色"></el-radio-button>
            </el-radio-group>
          </div>
          <div v-if="form.colourType == '系统配色'" class="global-themes">
            <div v-for="item in themeColor" :key="item.id" @click="checkColor(item)" class="theme-item" :class="{ active: isColor === item.id }">
              <div class="theme-item-main" :style="item.colorOne"></div>
              <div class="theme-item-main" :style="item.colorTwo"></div>
            </div>
          </div>

          <div v-if="form.colourType == '自定义配色'" class="global-themes">
            <el-form :model="form" ref="form" label-position="left" label-width="150px" style="width: 430px;">
              <el-form-item label="主题色">
                <div class="flex row-right">
                  <span style="line-height: 40px;">{{ form.theme }}</span>
                  <el-color-picker v-model="form.theme" class="color_div"></el-color-picker>
                  <el-button @click="resetting('theme')" type="text">重置</el-button>
                </div>
              </el-form-item>
              <el-form-item label="辅助色">
                <div class="flex row-right">
                  <span style="line-height: 40px;">{{ form.auxiliary }}</span>
                  <el-color-picker v-model="form.auxiliary" class="color_div"></el-color-picker>
                  <el-button @click="resetting('auxiliary')" type="text">重置</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="global-template-right">
          <div class="custom-theme-tips">预览效果</div>
          <div class="good-details-preview preview-item-scale">
            <img src="https://img01.yzcdn.cn/upload_files/2023/09/17/FsvXYwMOuFfGXqiD6lTEZEPGeAFH.png!large.webp" alt="" />
            <div class="main-price" :style="themeColor[isColor].colorFont">
              <span>￥</span>299-599
              <div class="member-price-tag">会员价</div>
            </div>
            <div class="footer-btn">
              <div class="add-cart-btn" v-if="form.colourType == '系统配色'" :style="themeColor[isColor].colorTwo">加入购物车</div>
              <div class="add-cart-btn" v-if="form.colourType == '自定义配色'" :style="{background:form.auxiliary}">加入购物车</div>
              <div class="now-buy-btn" v-if="form.colourType == '系统配色'" :style="themeColor[isColor].colorOne">立即购买</div>
              <div class="now-buy-btn" v-if="form.colourType == '自定义配色'" :style="{background:form.theme}">立即购买</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%;position: fixed;bottom: 0;background: #fff;text-align: center;padding: 10px 0;">
      <el-button @click="reset">取消</el-button>
      <el-button type="primary" @click="saveInfo">保存</el-button>
    </div>
  </div>
</template>

<script>
  import { EditInfo } from '@/api/user';
import SearchBar from "@/components/SearchBar";
import { mapGetters } from "vuex";

export default {
  name: "styleTheme",
  data() {
    return {
      isColor: "0",
      form: {
        theme: '#FFFFFF',
        auxiliary: '#FFFFFF',
        colourType: '系统配色',
      },
      themeColor: [
        {
          id: "0",
          colorFont: 'color: rgb(255, 68, 68)',
          colorOne: 'background-color: rgb(255, 68, 68)',
          colorTwo: 'background-color: rgb(255, 204, 204)',
          color:'rgb(255, 68, 68);rgb(255, 204, 204)'
        }, {
          id: "1",
          colorFont: 'color: rgb(255, 94, 21)',
          colorOne: 'background-color: rgb(255, 94, 21)',
          colorTwo: 'background-color: rgb(255, 147, 2)',
          color:'rgb(255, 94, 21);rgb(255, 147, 2)'
        }, {
          id: "2",
          colorFont: 'color: rgb(255, 84, 123)',
          colorOne: 'background-color: rgb(255, 84, 123)',
          colorTwo: 'background-color: rgb(255, 230, 233)',
          color:'rgb(255, 84, 123);rgb(255, 230, 233)'
        }, {
          id: "3",
          colorFont: 'color: rgb(255, 68, 68)',
          colorOne: 'background-color: rgb(255, 68, 68)',
          colorTwo: 'background-color: rgb(85, 85, 85)',
          color:'rgb(255, 68, 68);rgb(85, 85, 85)'
        }, {
          id: "4",
          colorFont: 'color: rgb(252, 198, 0)',
          colorOne: 'background-color: rgb(252, 198, 0)',
          colorTwo: 'background-color: rgb(29, 38, 46)',
          color:'rgb(252, 198, 0);rgb(29, 38, 46)'
        }, {
          id: "5",
          colorFont: 'color: rgb(101, 196, 170)',
          colorOne: 'background-color: rgb(101, 196, 170)',
          colorTwo: 'background-color: rgb(217, 247, 239)',
          color:'rgb(252, 198, 0);rgb(29, 38, 46)'
        }, {
          id: "6",
          colorFont: 'color: rgb(99, 190, 114)',
          colorOne: 'background-color: rgb(99, 190, 114)',
          colorTwo: 'background-color: rgb(226, 244, 227)',
          color:'rgb(99, 190, 114);rgb(226, 244, 227)'
        }, {
          id: "7",
          colorFont: 'color: rgb(74, 144, 226)',
          colorOne: 'background-color: rgb(74, 144, 226)',
          colorTwo: 'background-color: rgb(214, 234, 252)',
          color:'rgb(74, 144, 226);rgb(214, 234, 252)'
        }, {
          id: "8",
          colorFont: 'color: rgb(195, 167, 105)',
          colorOne: 'background-color: rgb(195, 167, 105)',
          colorTwo: 'background-color: rgb(243, 238, 225)',
          color:'rgb(195, 167, 105);rgb(243, 238, 225)'
        },
      ]
    }
  },

  components: {
    SearchBar,
  },

  computed: {
    ...mapGetters(["project"]),
  },
  created() {
    console.log("this.project:",this.project);
    let backgrounds=this.project.configEntity.backgroundColor.split(';');
    console.log("backgrounds:",backgrounds);
    if(backgrounds.length==2){
      let index = this.themeColor.findIndex((item) => item.color == this.project.configEntity.backgroundColor);
      if(index!=-1){
        this.isColor=this.themeColor[index].id;
      }
      else{
        this.form.theme=backgrounds[0];
        this.form.auxiliary=backgrounds[1];
      }

    }

  },

  methods: {
    checkColor(item) {
      this.isColor = item.id;

      console.log("item:",item);



    },
    add() {
      this.project.configEntity.hotList.push({ value: "" });
    },

    resetting(str) {
      if (str == 'theme') {
        this.form.theme = '#FFFFFF';
      } else {
        this.form.auxiliary = '#FFFFFF';
      }
    },
    saveInfo(){
      let background = null

      if(this.form.colourType == '系统配色'){
       // background =  this.themeColor[this.isColor].colorOne.replace(/\s/g,"").split(':')[1]

        background=this.themeColor[this.isColor].color;

      }else{
        //background = this.form.theme

       let list=this.form.theme+';'+this.form.auxiliary;
        background=list;

      }
      console.log("this.themeColor[this.isColor]:",this.themeColor[this.isColor]);


      let params1 = {backgroundColor:background,configId:this.project.configEntity.configId}
      this.project.configEntity.backgroundColor = background;

      console.log("this.project:",this.project);


      this.$store.commit('setProject',this.project)


      console.log("this.project:",this.project);

      EditInfo(params1).then(res =>{
        if(res.code == 0){
          this.$message({
            type: "success",
            message: "保存成功!",
          });
        }
      })
    },
    reset(){
      this.isColor = "0"
      this.form = {
        theme: '#FFFFFF',
        auxiliary: '#FFFFFF'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.global-template {
  width: 80%;
  margin: 10px;
  padding-top: 1px;
  background-color: #ffffff;

  &-left {
    width: 458px;
    margin: 24px 0 0 16px;
    flex-shrink: 0;
  }
  &-right {
    width: 800px;
    flex-grow: 1;
    border-left: 1px solid #e0e0e0;
    padding: 24px 0 0 16px;
    margin-bottom: 60px;
  }
  .custom-theme-tips {
    margin-bottom: 12px;
    max-width: 810px;
    color: #000;
    font-size: 14px;
    line-height: 20px;
  }
  .global-themes {
    width: 430px;
    margin-left: 0;
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
  }
  .theme-item-main {
    width: 24px;
    height: 24px;
  }
  .theme-item {
    display: flex;
    cursor: pointer;
    margin-bottom: 12px;
    margin-right: 12px;
  }
  .theme-item.active {
    outline: 1px solid #0080f9;
    outline-offset: 2px;
  }

  .preview-item-scale {
    width: 230px;
    margin-right: 8px;
    margin-bottom: 12px;
    position: relative;
    img {
      height: auto;
      max-width: 100%;
      font-size: 100%;
      vertical-align: baseline;
    }
    .main-price {
      position: absolute;
      left: 8px;
      top: 300px;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      font-size: 12px;
      font-weight: 500;
    }
    .footer-btn {
      display: flex;
      position: absolute;
      top: 385px;
      right: 0;
      font-size: 10px;
      .add-cart-btn {
        width: 70px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
      }
      .now-buy-btn{
        width: 70px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
      }
    }
  }

  .el-form-item__label {
    color: #a8a6a6;
  }
  .color_div {
    width: 60px;
    margin-left: 16px;
    margin-right: 20px;
  }

}
.global-template-title {
    margin: 16px;
    line-height: 26px;
    font-size: 20px;
    font-weight: 500;
  }
.nav-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
